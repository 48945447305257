<template>
  <div ref="cardSlider"
       class="keen-slider"
       :style="{ overflow: 'visible' }"
  >
    <slot />
  </div>
  <div v-if="slider && size === 'mobile'"
       class="flex items-center justify-center h-10 space-x-2"
  >
    <div v-for="(_dot, i) in length || slider.slides.length"
         :key="i"
         class="w-2 h-2 transition-colors rounded-full"
         :class="i === current ? ' bg-white opacity-100 shadow-whiteCircle' : 'bg-gradient-to-r from-gray-100 to-gray-100 opacity-30'"
    />
  </div>
</template>

<script setup lang="ts">
import { type KeenSliderInstance, useKeenSlider } from 'keen-slider/vue.es';
import { ref, watch } from 'vue';
import { useDetectSize } from '../composables/detect-size';

type SliderType = {
  length?: number;
  mobilePerView?: number;
  tabletPerView?: number;
  desktopPerView?: number;
  mobileSpacing?: number;
  tabletSpacing?: number;
  desktopSpacing?: number;
};

const props = defineProps<SliderType>();
const size = useDetectSize();
const current = ref(0);

const update = (e: KeenSliderInstance) => {
  current.value = e.track.details.rel;
};

// On length change we should update the slider instance
// If the delay is removed it will not allow to slide when changing the length correctly
watch(
  () => props.length,
  () => {
    setTimeout(() => {
      if (slider.value) slider.value.update(undefined, 0);
    }, 10);
  }
);

const [cardSlider, slider] = useKeenSlider({
  slides: {
    perView: props.desktopPerView ?? 3,
    spacing: props.desktopSpacing ?? 32
  },
  breakpoints: {
    '(max-width: 639px)': {
      slides: {
        perView: props.mobilePerView ?? 1,
        spacing: props.mobileSpacing ?? 16
      }
    },
    '(min-width: 640px) and (max-width: 767px)': {
      slides: {
        perView: props.tabletPerView ?? 1.5,
        spacing: props.mobileSpacing ?? 16
      }
    },
    '(min-width: 768px) and (max-width: 1023px)': {
      slides: {
        perView: 2.2,
        spacing: 16
      }
    }
  },
  slideChanged: e => update(e)
});
</script>
